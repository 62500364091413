$black: #1a1a1a;
$grey-700: #616161;
$grey-600: #757575;
$grey-500: #9e9e9e;
$grey-400: #bdbdbd;
$grey-300: #e0e0e0;
$grey-200: #eeeeee;
$grey-100: #f5f5f5;
$white: #ffffff;
$blue-900: #00535e;
$blue-700: #1a98a7;
$blue-100: #95d7df;
$blue-50: #d6eff2;
$red: #f44336;
$orange: #ffb300;
$purple: #ba68c8;
$pink: #f06292;
$lime: #cddc39;
$line: #00b900;
$green-900: #46994b;
$green-800: #6bad6f;
$green-700: #7db881;
$green-400: #a2cca5;
$green-300: #c8e0c9;
$green-100: #ecf5ed;
$green-50: #f6faf6;

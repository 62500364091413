@use '@angular/material' as mat;
// mat-icon + mat-badge not show up value
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

//mat.$theme-ignore-duplication-warnings: true;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
$livmap-green: (
  50: #f6faf6,
  100: #ecf5ed,
  200: #ecf5ed,
  300: #c8e0c9,
  400: #a2cca5,
  500: #46994b,
  600: #46994b,
  700: #46994b,
  800: #46994b,
  900: #46994b,
  A100: #46994b,
  A200: #46994b,
  A400: #46994b,
  A700: #46994b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$livmap-red: (
  50: #f44336,
  100: #f44336,
  200: #f44336,
  300: #f44336,
  400: #f44336,
  500: #f44336,
  600: #f44336,
  700: #f44336,
  800: #f44336,
  900: #f44336,
  A100: #f44336,
  A200: #f44336,
  A400: #f44336,
  A700: #f44336,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
// custom theme
$livmap-app-primary: mat.m2-define-palette($livmap-green);
$livmap-app-accent: mat.m2-define-palette(mat.$m2-red-palette, A200, A100, A400);
$livmap-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$livmap-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $livmap-app-primary,
      accent: $livmap-app-accent,
      warn: $livmap-warn,
    ),
  )
);

@include mat.core-theme($livmap-app-theme);
:root {
  @include mat.all-component-themes($livmap-app-theme);
}

//.livmap-admin-app-theme {
//  $livmap-admin-app-primary: mat.define-palette($livmap-red);
//  $livmap-admin-app-theme: mat.define-light-theme(
//      (
//        color: (
//          primary: $livmap-admin-app-primary,
//          accent: $livmap-app-accent,
//          warn: $livmap-warn,
//        ),
//      )
//  );
//  //@include mat.core-theme($livmap-admin-app-theme);
//  @include mat.all-component-themes($livmap-admin-app-theme);
//}

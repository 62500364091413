/* You can add global styles to this file, and also import other style files */
@import 'mapbox-gl/dist/mapbox-gl.css';
@import './app/styles/style';
@import './app/styles/variable';

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1.7;
}

html,
body {
  min-height: 100%;
  height: 100%;
  color: $black;
  background-color: $grey-100;
}
body {
  margin: 0;
  font-family: Noto Sans JP, Roboto, 'Helvetica Neue', sans-serif;
}
* {
  box-sizing: border-box;
}
